import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import Login from './pages/Login'
import ScrollToTop from './components/common/ScrollToTop'
import Layout from './components/layout'
import ForgotPassword from './pages/ForgotPassword'
import LoginOtp from './pages/LoginOtp'
import ChangePassword from './pages/ChangePassword'
import Dashboard from './pages/AuthPages/Dashboard'
import CareTeam from './pages/AuthPages/CareTeam'
import CreateCareTeam from './pages/AuthPages/CreateCareTeam'
import Profile from './pages/AuthPages/Profile'
import MyAppointment from './pages/AuthPages/MyAppointment'
import SavedDocument from './pages/AuthPages/SavedDocument'
import SavedDocumentView from './pages/AuthPages/SavedDocumentView'
import BookAppointment from './pages/AuthPages/BookAppointment'
import BookAppointmentThanks from './pages/AuthPages/BookAppointmentThanks'
import TalkToDoctor from './pages/AuthPages/TalkToDoctor'
import Help from './pages/AuthPages/Help'
import Assessments from './pages/AuthPages/Assessments'
import AssessmentTest from './pages/AuthPages/AssessmentTest'
import MedicalRecord from './pages/AuthPages/MedicalRecord'
import MyMedicines from './pages/AuthPages/MyMedicines'
import AppointmentDetails from './pages/AuthPages/AppointmentDetails'
import RpmAlertManagement from './pages/AuthPages/RpmAlertManagement'
import RPMAlertManagementDetails from './pages/AuthPages/RPMAlertManagementDetails'
import ProtectedRoute from './components/common/ProtectedRoute'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from './utils/store'
import BehaviouralHealth from './pages/AuthPages/BehaviouralHealth'
import BehaviouralHealthDetails from './pages/AuthPages/BehaviouralHealthDetails'
import LabTests from './pages/AuthPages/LabTests'
import CallNow from './pages/AuthPages/CallNow'
import BookLabtest from './pages/AuthPages/BookLabtest'
import LabtestCart from './pages/AuthPages/LabtestCart'
import LabTestDetails from './pages/AuthPages/LabTestDetails'
import CarePlan from './pages/AuthPages/CarePlan'
import LabCartSummary from './pages/AuthPages/LabCartSummary'
import BookedLabDetails from './pages/AuthPages/BookedLabDetails'
import { setAuthToken, setIsCareNavigator, setIsProfileComplete, setPatientLoginData } from './utils/store/auth/AuthSlice'
import { getCareNavigatorPatientApi } from './utils/api/services'
import { toast } from 'react-toastify'
import Chat from './pages/AuthPages/Chat'
import AssessmentCheckResult from './pages/AuthPages/AssessmentCheckResult'
import Setting from './pages/AuthPages/Setting'
import HraResult from './pages/AuthPages/HraResult'
import PO60Connect from './pages/PO60Connect'
import OmronDevice from './pages/AuthPages/OmronDevice'

const App = () => {
  const [loading, setLoading] = useState(false)
  const token = useSelector((state: RootState) => state.auth.token);
  const { isCareNavigator } = useSelector((state: RootState) => state.auth)
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate();

  const fetchPatientLogin = async () => {
    try {
      setLoading(true)
      const result = await getCareNavigatorPatientApi()
      if (result?.success) {
        setLoading(false)
        if (result?.userDetails) {
          dispatch(setPatientLoginData(result?.userDetails && result?.userDetails[0]))
          localStorage.setItem('patientInfo', JSON.stringify(result?.userDetails[0]))
          localStorage.setItem('isProfileCompleted', JSON.stringify(result?.userDetails[0]?.isProfileCompleted === 1 ? true : false))
          dispatch(setIsProfileComplete(result?.userDetails[0]?.isProfileCompleted === 1 ? true : false))
          if (result?.userDetails[0]?.isFirstLogin === 0 || result?.userDetails[0]?.isProfileCompleted === 0) {
            navigate('/myaccount/profile')
          }
          else {
            navigate('/dashboard')
          }
        }
      }
      else {
        setLoading(false)
        toast.error(result?.message)
      }
    } catch (error: any) {
      toast.error(error?.data?.errors || error?.message);
    }
  }
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tokenFromUrl = queryParams.get('token');
    const loggedBy = queryParams.get('loggedBy');
    if (tokenFromUrl && loggedBy) {
      fetchPatientLogin();
      dispatch(setAuthToken(tokenFromUrl))
      localStorage.setItem("care_navigator", JSON.stringify(loggedBy === 'careNavigator' ? true : false))
      dispatch(setIsCareNavigator(loggedBy === 'careNavigator' ? true : false))
    }
  }, [])


  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/otp' element={<LoginOtp />} />
        <Route path='/change-password' element={<ChangePassword />} />
        {
          (!isCareNavigator && token) &&
          <Route path='CallNow' element={<CallNow />} />
        }
        <Route path="/*"
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }>
          <Route path='myaccount/profile' element={<Profile />} />
          <Route path='dashboard' element={<Dashboard />} />
          <Route path='CareTeam' element={<CareTeam />} />
          <Route path='CareTeam/Create' element={<CreateCareTeam />} />
          <Route path='MyAppointments' element={<MyAppointment />} />
          <Route path='MyAppointments/book' element={<BookAppointment />} />
          <Route path='MyAppointments/book/thanks' element={<BookAppointmentThanks />} />
          <Route path='SavedDocuments' element={<SavedDocument />} />
          <Route path='SavedDocuments/view' element={<SavedDocumentView />} />
          <Route path='help' element={<Help />} />
          <Route path='Assessments' element={<Assessments />} />
          <Route path='MedicalRecord' element={<MedicalRecord />} />
          <Route path='MyMedicine' element={<MyMedicines />} />
          <Route path='MyAppointments/details' element={<AppointmentDetails />} />
          <Route path='RPMAlertManagement' element={<RpmAlertManagement />} />
          <Route path='RPMAlertManagement/details' element={<RPMAlertManagementDetails />} />
          <Route path='BehaviouralHealth' element={<BehaviouralHealth />} />
          <Route path='LabTests' element={<LabTests />} />
          <Route path='device/omron' element={<OmronDevice />} />
          <Route path='myaccount/settings' element={<Setting />} />
          {
            !isCareNavigator &&
            <>
              <Route path='TalkToDoctor' element={<TalkToDoctor />} />
              {/* <Route path='CallNow' element={<CallNow />} /> */}
              <Route path='Assessments/test' element={<AssessmentTest />} />
              <Route path='BehaviouralHealth/test' element={<BehaviouralHealthDetails />} />
            </>
          }
          <Route path='LabTests/Book' element={<BookLabtest />} />
          <Route path='LabTests/Cart' element={<LabtestCart />} />
          <Route path='LabTests/Cart_Summary' element={<LabCartSummary />} />
          <Route path='LabTests/details' element={<LabTestDetails />} />
          <Route path='bookedLab/details' element={<BookedLabDetails />} />
          <Route path='careplan' element={<CarePlan />} />
          <Route path='inbox' element={<Chat />} />
          <Route path='Assessments/result' element={<AssessmentCheckResult />} />
          <Route path='Assessments/hra-result' element={<HraResult />} />
          <Route path='PO60Connect' element={<PO60Connect />} />
          <Route path='*' element={<Dashboard />} />
          <Route
            path="/*"
            element={<Navigate to={"/dashboard"} replace />}
          />
        </Route>
        <Route
          path="/*"
          element={<Navigate to={!token ? "/login" : ''} replace />}
        />
      </Routes>
    </>
  )
}

export default App