import { Tab, Tabs } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CustomTabPanel, a11yProps } from './CustomTabPanel'
import styles from "../../assets/styles/layout.module.css"
import NotificationCard from './NotificationCard'
import { getNotificationApi, removeNotificationApi } from '../../utils/api/services'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { RootState } from '../../utils/store'
import ConfirmModal from './ConfirmModal'
import useSocket from '../../utils/functions/useSocket'
const NotificationBox = () => {
    const [value, setValue] = useState(0);
    const [notificationData, setNotificationData] = useState<NotificationHeader[] | []>([]);
    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const { LoginData } = useSelector((state: RootState) => state.auth)
    const [particularData, setParticularData] = useState<NotificationHeader | null>(null)
    const { connect, disconnect, sendMessage } = useSocket();

    useEffect(() => {
        connect();
        return () => {
          disconnect();
        };
      }, [connect, disconnect]);

    const CloseConfirmModal = () => {
        setOpenConfirmModal(false)
    }

    const fetchNotification = async (data: {
        role: string;
        referenceId: number;
        filter: string;
    }) => {
        try {
            // const result = await getNotificationApi(data);
            const result = await sendMessage('getNotifications', data);
            if (result?.success) {
                setNotificationData(result?.notifications?.notifications)
            }
        } catch (error: any) {
            toast.error(error?.data?.errors || error?.message);
        }
    }
    useEffect(() => {
        if (LoginData) {
            const _Filter = value === 0 ? 'all' : value === 1 ? 'today' : value === 2 ? 'this_week' : value === 3 ? 'this_month' : 'all'
            fetchNotification({ role: LoginData?.role, referenceId: LoginData?.loggedPatientId, filter: _Filter });
        }
    }, [value, sendMessage]);
    
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const removeNotification = async (data: NotificationHeader) => {
        setParticularData(data)
        setOpenConfirmModal(true)
    }

    const confirmSubmit = async () => {
        try {
            if (particularData?.id) {
                setBtnLoading(true)
                const result = await removeNotificationApi({ notificationId: particularData?.id });
                if (result?.success) {
                    setBtnLoading(false)
                    toast.success(result?.message)
                    if (LoginData) {
                        const _Filter = value === 0 ? 'all' : value === 1 ? 'today' : value === 2 ? 'this_week' : value === 3 ? 'this_month' : 'all'
                        fetchNotification({ role: LoginData?.role, referenceId: LoginData?.loggedPatientId, filter: _Filter });
                    }
                    CloseConfirmModal();
                }
                else {
                    setBtnLoading(false)
                    toast.error(result?.message)
                }
            }
        } catch (error: any) {
            setBtnLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }
    return (
        <div className={styles.NotificationBoxMain}>
            <Tabs value={value} onChange={handleChange} className="custumTabs">
                <Tab label="All" {...a11yProps(0)} />
                <Tab label="Today" {...a11yProps(1)} />
                <Tab label="This Week" {...a11yProps(2)} />
                <Tab label="This Month" {...a11yProps(3)} />
            </Tabs>
            <CustomTabPanel value={value} index={0} noPaddingBottom={true}>
                <div className={styles.notificationBox}>
                    {
                        notificationData.map((item, index) => {
                            return (
                                <NotificationCard removeNotification={removeNotification} key={index} item={item} />
                            )
                        })
                    }
                </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1} noPaddingBottom={true}>
                <div className={styles.notificationBox}>
                    {
                        notificationData.map((item, index) => {
                            return (
                                <NotificationCard removeNotification={removeNotification} key={index} item={item} />
                            )
                        })
                    }
                </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2} noPaddingBottom={true}>
                <div className={styles.notificationBox}>
                    {
                        notificationData.map((item, index) => {
                            return (
                                <NotificationCard removeNotification={removeNotification} key={index} item={item} />
                            )
                        })
                    }
                </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3} noPaddingBottom={true}>
                <div className={styles.notificationBox}>
                    {
                        notificationData.map((item, index) => {
                            return (
                                <NotificationCard removeNotification={removeNotification} key={index} item={item} />
                            )
                        })
                    }
                </div>
            </CustomTabPanel>
            <ConfirmModal
                openConfirmModal={openConfirmModal}
                CloseConfirmModal={CloseConfirmModal}
                confirmSubmit={confirmSubmit}
                btnLoading={btnLoading}
                title='Are you sure, you want to delete this notification'
                DoNotCloseNotification={true}
            />
        </div>
    )
}

export default NotificationBox