import CustomContainer from "../../components/common/CustomContainer"
import styles from "../../assets/styles/appointments.module.css"
import { Tab, Tabs } from "@mui/material"
import { CustomTabPanel, a11yProps } from "../../components/common/CustomTabPanel"
import { useState } from "react"
import UpComing from "../../components/myAppointments/UpComing"
import Completed from "../../components/myAppointments/Completed"
import Cancelled from "../../components/myAppointments/Cancelled"
import { useNavigate } from "react-router-dom"
import ConfirmModal from "../../components/common/ConfirmModal"
import UpdateProfileModal from "../../components/chat/UpdateProfileModal"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../utils/store"
import { getConnectTokenApi } from "../../utils/api/services"
import { setAuthConnectToken } from "../../utils/store/auth/AuthSlice"
import { toast } from "react-toastify"
import { allergiesReducer, healthProblemsReducer, MedicationsReducer, symptomsReducer } from "../../utils/store/profile/ProfileSlice"

const MyAppointment = () => {
    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    const [openProfileModal, setOpenProfileModal] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const [particularData, setParticularData] = useState<appointmentTypes | null>(null)
    const [value, setValue] = useState(0);
    const { LoginData } = useSelector((state: RootState) => state.auth)
    const dispatch = useDispatch<AppDispatch>()

    const navigate = useNavigate();
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleJoinNow = (item: appointmentTypes) => {
        setOpenConfirmModal(true)
        setParticularData(item)
    }

    const closeProfileModal = () => {
        setOpenProfileModal(false)
        handleCallNow();
    }

    const ConfirmUpdateProfile = async () => {
        handleCallNow();
    }

    const handleCallNow = async () => {
        try {
            const sendData = {
                "uuid": LoginData?.uuid,
                "role": "patient-app"
            }
            const result = await getConnectTokenApi(sendData);
            if (result?.access_token) {
                dispatch(setAuthConnectToken(result?.access_token))
                // navigate('/CallNow', {
                //     state: {
                //         appointmentId: particularData?.appointmentId
                //     }
                // })
                const url = `/CallNow?appointmentId=${particularData?.appointmentId}`;
                window.open(url, '_blank');
                setOpenConfirmModal(false)
                setOpenProfileModal(false)
            }
        } catch (error: any) {
            toast.error(error?.data?.errors || error?.message);
        }
    }
    const DontWantToChange = () => {
        setOpenConfirmModal(false)
        handleCallNow();
    }
    const CloseConfirmModal = () => {
        setOpenConfirmModal(false)
    }
    const confirmSubmit = () => {
        setOpenProfileModal(true)
        setOpenConfirmModal(false)
        dispatch(MedicationsReducer({ path: 'medications' }));
        dispatch(healthProblemsReducer({ path: 'healthProblems' }));
        dispatch(symptomsReducer({ path: 'symptoms' }));
        dispatch(allergiesReducer({ path: 'allergies' }));
    }




    return (
        <CustomContainer>
            <div className={'AllPageMain'}>
                <div className={'HeadAndButton'}>
                    <h2>My appointments</h2>
                    <button className='fillButton' onClick={() => navigate('/MyAppointments/book')}>Book a Appointment</button>
                </div>
                <div className={styles.appointmentsMain}>
                    <Tabs value={value} onChange={handleChange} className="custumTabs">
                        <Tab label="Upcoming" {...a11yProps(0)} />
                        <Tab label="Completed" {...a11yProps(1)} />
                        <Tab label="Cancelled" {...a11yProps(2)} />
                    </Tabs>
                    <CustomTabPanel value={value} index={0}>
                        <UpComing handleJoinNow={handleJoinNow} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <Completed />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <Cancelled />
                    </CustomTabPanel>
                </div>
            </div>
            <ConfirmModal
                openConfirmModal={openConfirmModal}
                CloseConfirmModal={CloseConfirmModal}
                confirmSubmit={confirmSubmit}
                DontWantToChange={DontWantToChange}
                btnLoading={btnLoading}
                title={`Do you want to update your profile?`}
            />
            <UpdateProfileModal
                openProfileModal={openProfileModal}
                btnLoading={btnLoading}
                closeProfileModal={closeProfileModal}
                ConfirmUpdateProfile={ConfirmUpdateProfile}
            />
        </CustomContainer>
    )
}

export default MyAppointment