import React, { useEffect, useState } from 'react';
import CustomContainer from '../../components/common/CustomContainer';
import styles from "../../assets/styles/assessments.module.css";
import AssessmentCard from '../../components/common/AssessmentCard';
import { useNavigate } from 'react-router-dom';
import { sliderSettings } from '../../utils/functions/common';
import Slider from "react-slick";
import { getAssessmentCompletedApi, getAssessmentsApi } from '../../utils/api/services';
import { toast } from 'react-toastify';
import Loader from '../../components/common/Loader';
import { useSelector } from 'react-redux';
import { RootState } from '../../utils/store';
import { Images } from '../../utils/functions/Images';

const Assessments: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [allAssessmentData, setAllAssessmentData] = useState<Assessment[]>([]);
    const [commonAssessmentData, setCommonAssessmentData] = useState<Assessment[]>([]);
    const [takenAssessmentData, setTakenAssessmentData] = useState<Assessment[]>([]);
    const { isCareNavigator } = useSelector((state: RootState) => state.auth)

    const navigate = useNavigate();
    const groupByAssessmentType = (assessments: Assessment[]) => {
        return assessments.reduce((acc: { [key: string]: Assessment[] }, assessment: Assessment) => {
            const { assessmentType } = assessment;
            if (!acc[assessmentType]) {
                acc[assessmentType] = [];
            }
            acc[assessmentType].push(assessment);
            return acc;
        }, {});
    };

    const fetchAssessment = async (data: { type: number }) => {
        try {
            setLoading(true);
            const result = await getAssessmentsApi(data);
            if (result?.success) {
                setLoading(false);
                const groupedAssessments = groupByAssessmentType(result.assessments);
                setAllAssessmentData(groupedAssessments['1'] || []);
                setCommonAssessmentData(groupedAssessments['2'] || []);
                // setTakenAssessmentData(groupedAssessments['3'] || []);
            } else {
                setLoading(false);
                toast.error(result.message);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.data?.errors || error?.message);
        }
    };

    const fetchCompletedAssessment = async () => {
        try {
            const result = await getAssessmentCompletedApi();
            if (result?.success) {
                setTakenAssessmentData(result?.assessments)
            }
        } catch (error: any) {
            toast.error(error?.data?.errors || error?.message);
        }
    }

    useEffect(() => {
        fetchAssessment({ type: 1 });
        fetchCompletedAssessment();
    }, []);

    const handleStartNow = (id: number, assessmentName: string, totalLevels: number) => {
        navigate('/Assessments/test', {
            state: {
                id: id,
                assessmentName: assessmentName,
                totalLevel: totalLevels
            }
        });
    }

    const handleCheckResult = (id: number, assessmentName: string, totalLevels: number) => {
        if (id === 7) {
            navigate('/Assessments/hra-result', {
                state: {
                    id: id,
                    assessmentName: assessmentName,
                    totalLevel: totalLevels,
                    isResult: true
                }
            })
        } else {
            navigate('/assessments/result', {
                state: {
                    id: id,
                    assessmentName: assessmentName,
                    totalLevel: totalLevels
                }
            })
        }

    }
    return (
        <CustomContainer>
            <div className='AllPageMain'>
                <div className='HeadAndButton'>
                    <h2>Assessments</h2>
                </div>
                <div className={styles.assessmentsMain}>
                    <div className={styles.bannerSec}>
                        <img src={Images.assessmentBanner} alt="assessment banner" />
                    </div>
                    {
                        loading ? <Loader />
                            :
                            <>
                                {
                                    takenAssessmentData?.length > 0 &&
                                    <div className={styles.assessmentsTakenByYou}>
                                        <div className={styles.sectionHead}>
                                            <h3>Assessments taken by you</h3>
                                        </div>
                                        <div className={styles.assessmentTakenMain}>
                                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
                                                {takenAssessmentData.map((assessment, index) => (
                                                    <AssessmentCard
                                                        key={index}
                                                        assessmentName={assessment?.assessmentName}
                                                        assessmentImage={assessment?.assessmentImage}
                                                        id={assessment?.assessmentId}
                                                        handleStartNow={handleCheckResult}
                                                        taken={true}
                                                        btnText={'Check Results'}
                                                        totalLevels={assessment?.totalLevels}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    !isCareNavigator &&
                                    <>
                                        {
                                            commonAssessmentData?.length > 0 &&
                                            <div className={styles.assessmentsTakenByYou}>
                                                <div className={styles.sectionHead}>
                                                    <h3>Most common Assessments</h3>
                                                </div>
                                                <div className={styles.assessmentTakenMain}>
                                                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
                                                        {commonAssessmentData.map(assessment => (
                                                            <AssessmentCard
                                                                key={assessment.id}
                                                                assessmentName={assessment?.assessmentName}
                                                                assessmentImage={assessment?.assessmentImage}
                                                                id={assessment?.id}
                                                                btnText={'Start Now'}
                                                                handleStartNow={handleStartNow}
                                                                totalLevels={assessment?.totalLevels}
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {allAssessmentData?.length > 0 &&
                                            <div className={styles.assessmentsTakenByYou}>
                                                <div className={styles.sectionHead}>
                                                    <h3>All Assessments</h3>
                                                </div>
                                                <div className={styles.assessmentTakenMain}>
                                                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
                                                        {allAssessmentData.map(assessment => (
                                                            <AssessmentCard
                                                                key={assessment.id}
                                                                assessmentName={assessment?.assessmentName}
                                                                assessmentImage={assessment?.assessmentImage}
                                                                id={assessment?.id}
                                                                btnText={'Start Now'}
                                                                handleStartNow={handleStartNow}
                                                                totalLevels={assessment?.totalLevels}
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }

                            </>
                    }

                </div>
            </div>
        </CustomContainer>
    );
}

export default Assessments;
