import CustomContainer from '../../components/common/CustomContainer'
import styles from "../../assets/styles/behaviouralhealth.module.css";
import Card from '../../components/behaviouralhealth/Card';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getAssessmentsApi } from '../../utils/api/services';
import { toast } from 'react-toastify';
import Loader from '../../components/common/Loader';
import NoDataFound from '../../components/common/NoDataFound';
import { Images } from '../../utils/functions/Images';
const BehaviouralHealth = () => {
    const [HealthData, setHealthData] = useState<Assessment[] | []>([])
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const fetchHealthData = async (data: { type: number }) => {
        try {
            setLoading(true);
            const result = await getAssessmentsApi(data)
            if (result?.success) {
                setLoading(false);
                setHealthData(result?.assessments)
            } else {
                setLoading(false);
                toast.error(result.message);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.data?.errors || error?.message);
        }
    }
    useEffect(() => {
        fetchHealthData({ type: 2 });
    }, []);

    const handleStartNow = (item: Assessment) => {
        navigate(`/BehaviouralHealth/test`, {
            state: {
                id: item?.id,
                assessmentName: item?.assessmentName
            }
        })
    }

    return (
        <CustomContainer>
            <div className='AllPageMain'>
                <div className='HeadAndButton'>
                    <h2>Behavioural Health</h2>
                </div>
                <div className={styles.bannerSection}>
                    <img src={Images.BehavioralBanner} alt="behavioral health banner" />
                </div>
                {
                    loading ? <Loader />
                        :
                        <div className={styles.main}>
                            {
                                HealthData?.length > 0 ?
                                    <div className={'grid grid-cols-3 gap-6'}>
                                        {
                                            HealthData?.map((item, index) => {
                                                return (
                                                    <Card handleStartNow={handleStartNow} key={index} item={item} />
                                                )
                                            })
                                        }
                                    </div>
                                    : <NoDataFound />
                            }
                        </div>
                }
            </div>
        </CustomContainer>
    )
}

export default BehaviouralHealth