import React, { FormEvent, useEffect, useState } from 'react'
import styles from "../assets/styles/login.module.css"
import { FormGroup, IconButton, InputAdornment, InputLabel, TextField } from '@mui/material'
import { HidePasswordIcon, LockIcon, MailIcon, ShowPasswordIcon } from '../utils/functions/Icons'
import logo from "../assets/images/login/logo.svg"
import girlImg from "../assets/images/login/groupimg.png"
import { Link, useNavigate } from 'react-router-dom'
import { loginApi, PatientDetailsCheckApi } from '../utils/api/services'
import { useDispatch } from 'react-redux'
import { setAuthToken, setIsProfileComplete, setPatientLoginData } from '../utils/store/auth/AuthSlice'
import { toast } from 'react-toastify'
import BtnLoader from '../components/common/BtnLoader'
interface LoginTypes {
  email: string,
  password: string
}
const Login = () => {
  const [loginData, setLoginData] = useState<LoginTypes>({
    email: '',
    password: ''
  })
  const [showPassword, setShowPassword] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChangeLogin = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    setLoginData({
      ...loginData,
      [name]: value
    })
  }



  const handleLogin = async () => {
    try {
      setBtnLoading(true);
      const result = await loginApi({
        email: loginData?.email,
        password: loginData?.password
      });
      if (result?.success) {
        setBtnLoading(false);
        toast.success(result?.message)
        dispatch(setAuthToken(result?.token))
        if (result?.userDetails) {
          dispatch(setPatientLoginData(result?.userDetails && result?.userDetails[0]))
          localStorage.setItem('patientInfo', JSON.stringify(result?.userDetails[0]))
          localStorage.setItem('isProfileCompleted', JSON.stringify(result?.userDetails[0]?.isProfileCompleted === 1 ? true : false))
          dispatch(setIsProfileComplete(result?.userDetails[0]?.isProfileCompleted === 1 ? true : false))
          if (result?.userDetails[0]?.isFirstLogin === 0 || result?.userDetails[0]?.isProfileCompleted === 0) {
            navigate('/myaccount/profile')
          }
          else {
            navigate('/dashboard')
          }
        }
      }
      else {
        setBtnLoading(false);
        toast.error(result?.message)
      }
    } catch (error: any) {
      setBtnLoading(false);
      toast.error((error?.data?.errors?.length !== 0 && error?.data?.errors) || error?.message);
    }

  }

  const fetchCheckLoginDetails = async (e: FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setBtnLoading(true);
      const result = await PatientDetailsCheckApi(loginData?.email);
      if (result?.success && result?.data) {
        handleLogin();
      }
      if (result?.errors === 203) {
        setBtnLoading(false);
        toast.error(result?.message)
      }
    } catch (error: any) {
      setBtnLoading(false);
      toast.error(error?.message);
    }
  }
  return (
    <div className={styles.loginMain}>
      <div className="grid grid-cols-1 md:grid-cols-7 gap-6 h-full">
        <section className={`col-span-4 ${styles.bannerSection}`}>
          <div className={styles.logo}>
            <img src={logo} alt="logo" />
          </div>
          <div className={styles.girlImg}>
            <img src={girlImg} alt="girl image" />
          </div>
        </section>
        <section className={`col-span-3 ${styles.formSection}`}>
          <div className={styles.formBox}>
            <div className={styles.loginHead}>
              <h3>Hello !</h3>
              <p>Welcome to AkosMD</p>
            </div>
            <form noValidate={false} onSubmit={fetchCheckLoginDetails}>
              <FormGroup className="customFormGroup">
                <TextField
                  className={`custumTextField ${styles.InputStyle}`}
                  id="email"
                  name="email"
                  type='email'
                  onChange={handleChangeLogin}
                  required
                  value={loginData?.email}
                  placeholder='Work Email address'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormGroup>
              <FormGroup className="customFormGroup mt-5">
                <TextField
                  type={showPassword ? 'text' : 'password'}
                  className={'custumTextField'}
                  id="password"
                  name="password"
                  onChange={handleChangeLogin}
                  required
                  value={loginData?.password}
                  placeholder='Password'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>loginData?.password && setShowPassword(!showPassword)}
                        >
                          {showPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormGroup>
              <button
                type='submit'
                className='fillButton w-full mt-8'
                disabled={btnLoading}
              >
                {
                  btnLoading &&
                  <BtnLoader />
                }
                LOGIN
              </button>
              <div className={styles.forgotPassword}>
                <Link to={'/forgot-password'}>Forgot Password ?</Link>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Login