import React, { FormEvent, useEffect, useState } from 'react';
import styles from "../assets/styles/login.module.css";
import { FormGroup } from '@mui/material';
import logo from "../assets/images/login/logo.svg";
import girlImg from "../assets/images/login/groupimg.png";
import OTPInput from 'react-otp-input';
import { useLocation, useNavigate } from 'react-router-dom';
import { resendOtpApi, verifyOtpApi } from '../utils/api/services';
import { toast } from 'react-toastify';
import BtnLoader from '../components/common/BtnLoader';

const LoginOtp: React.FC = () => {
    const [otp, setOtp] = useState<string>('');
    const [timer, setTimer] = useState<number>(0);
    const [btnLoading, setBtnLoading] = useState(false);
    const [isTimerActive, setIsTimerActive] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            setBtnLoading(true)
            const result = await verifyOtpApi({
                email: location.state,
                otp: otp
            });
            if (result?.success) {
                setBtnLoading(false)
                toast.success(result?.message);
                navigate('/change-password', {
                    state: location.state
                });
            } else {
                setBtnLoading(false)
                toast.error(result?.message);
            }
        } catch (error: any) {
            setBtnLoading(false)
            toast.error(error?.data?.errors || error?.message);;
        }
    };

    const handleResendOtp = async () => {
        try {
            const result = await resendOtpApi({
                email: location.state
            });
            if (result?.success) {
                toast.success(result?.message);
                startTimer();
            } else {
                toast.error(result?.message);
            }
        } catch (error: any) {
            toast.error(error?.data?.errors || error?.message);;
        }
    };

    const startTimer = () => {
        setTimer(30);
        setIsTimerActive(true);
    };

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;
        if (isTimerActive && timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else if (timer === 0 && isTimerActive) {
            setIsTimerActive(false);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [timer, isTimerActive]);

    useEffect(() => {
        startTimer();
    }, []);

    return (
        <div className={styles.loginMain}>
            <div className="grid grid-cols-1 md:grid-cols-7 gap-6 h-full">
                <section className={`col-span-4 ${styles.bannerSection}`}>
                    <div className={styles.logo}>
                        <img onClick={() => navigate('/login')}  src={logo} alt="logo" />
                    </div>
                    <div className={styles.girlImg}>
                        <img src={girlImg} alt="girl image" />
                    </div>
                </section>
                <section className={`col-span-3 ${styles.formSection}`}>
                    <div className={styles.formBox}>
                        <div className={styles.loginHead}>
                            <h4>OTP</h4>
                        </div>
                        <form noValidate={false} onSubmit={handleSubmit}>
                            <FormGroup className="customFormGroup">
                                <OTPInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    containerStyle={'OtpInputMain'}
                                    renderInput={(props) => <input {...props} required className='custumOtpinput' />}
                                />
                            </FormGroup>
                            <div className={styles.resendOtp}>
                                <h3
                                    onClick={isTimerActive ? undefined : handleResendOtp}
                                    className={isTimerActive ? styles.resendOtpDisabled : styles.resendOtpActive}
                                >
                                    Resend OTP?
                                </h3>
                                {isTimerActive && <p>{`00:${timer < 10 ? `0${timer}` : timer}`}</p>}
                            </div>
                            <button
                                type='submit'
                                className='fillButton w-full mt-8'
                                disabled={btnLoading}
                            >
                                {
                                    btnLoading &&
                                    <BtnLoader />
                                }
                                Change Password
                            </button>
                        </form>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default LoginOtp;
